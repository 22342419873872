<template>
  <v-container class="fill-height" fluid>
    <v-row class="pb-6 pt-0 text-center" justify="center" align="center">
      <v-col md="4" cols="10">
        <v-img
          src="img/logo.png"
          max-height="80px"
          contain
          transition="false"
        ></v-img>
        <p class="font-weight-bold">
          Anliegen melden für Objekt:<br />{{ $route.query.item.street }}
          {{ $route.query.item.housenumber }} {{ $route.query.item.plz }} {{ $route.query.item.citys }}
        </p>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="12" class="pa-0">
              <v-text-field
                :rules="nameRules"
                v-model="ticketform.name"
                label="Ihr Name"
                class="custom-label-color"
                append-icon="fas fa-signature"
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12" class="pa-0">
              <v-text-field
                label="Ihre Email"
                required
                v-model="ticketform.email"
                class="custom-label-color"
                append-icon="fas fa-envelope"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12" class="pa-0">
              <v-select
                :rules="grundRules"
                v-model="ticketform.subject"
                :items="[
                  'Notfalldienst',
                  'Allgemeine, handwerkliche Dienstleistung',
                  'Reparatur Sanitäranlage',
                  'Reparatur Heizungsanlage',
                  'Reparatur Elektroinstallation',
                  'Möbel Montage / Demontage',
                  'Entrümpelung',
                  'Professionelle Malerarbeiten',
                  'Fußbodenverlegung',
                  'Fenster- und Rahmenreinigung',
                  'Sonstige Anliegen',
                  'Beanstandung'
                ]"
                label="Grund"
                class="custom-label-color"
                append-icon="fas fa-question-circle"
              ></v-select>
            </v-col>

              <v-col cols="12" md="12" class="pa-0">
              <v-text-field
                label="Zusätzliche Informationen"
                required
                v-model="ticketform.floorinfo"
                placeholder="Etage, Klingel, Nachbar..."
                class="custom-label-color"
                append-icon="fas fa-info"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="12" class="pa-0">
              <div style="font-size: 17px" class="text-left">Priorität</div>
              <v-radio-group v-model="ticketform.prio" mandatory row>
                <v-radio
                  label="Normal"
                  class="custom-label-color"
                  value="Normal"
                ></v-radio>
                <v-radio
                  label="Hoch"
                  class="custom-label-color"
                  value="Hoch"
                ></v-radio>
              </v-radio-group>
            </v-col>
            <v-col cols="12" md="12" class="pa-0">
              <v-textarea
                name="details"
                class="custom-label-color"
                label="Beschreibung"
                id="id"
                v-model="ticketform.details"
                append-icon="fas fa-comment"
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" class="pa-0">
              <v-btn class="primary" :loading="loading" @click="sendform()"
                ><v-icon class="pr-2">fas fa-check-circle</v-icon
                >Absenden</v-btn
              >
            </v-col>
              <v-col cols="12" md="12" class="">
                <v-subheader><div><v-icon class="pr-2">fas fa-user-circle</v-icon>Zuständige Hausmeister:  {{ $route.query.item.facilitymanager.title }} {{ $route.query.item.facilitymanager.firstname }} {{ $route.query.item.facilitymanager.lastname }} Telefon: {{ $route.query.item.facilitymanager.phone }}</div> </v-subheader>

              </v-col>
          </v-row>
        </v-form>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      name: "",

      loading: false,
      ticketform: {
        name: "",
        email: "",
        prio: null,
        subject: "",
        floorinfo: "",
        details: "",
      },
      valid: true,
      nameRules: [(v) => !!v || "Name ist notwendig"],
      grundRules: [(v) => !!v || "Wählen Sie einen Grund aus"],
    };
  },
  methods: {
    sendform() {
      //console.log(this);
      //
      //setTimeout(function () { this.$router.push('/') }.bind(this), 2000)

      if(this.$refs.form.validate()){
      this.loading = true;
      axios
        .post("/api/sendForm", {
          item: this.$route.query.item,
          form: this.ticketform,
        })
        .then(
          (response) => {
            console.log(response);
            this.loading = false;
            this.$router.push('/');
            this.$root.$refs.snackbar.showmessage("Anfrage wurde gesendet.", "success");
          },
          (error) => {
            this.$root.$refs.snackbar.showmessage("Anfrage wurde nicht gesendet.", "error");
          }
        );
      }
    },
  },
};
</script>

<style>
</style>