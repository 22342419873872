require('./bootstrap');
import Vue from 'vue'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import VueRouter from 'vue-router'
import VueQrcodeReader from 'vue-qrcode-reader'
Vue.use(VueRouter)
Vue.use(Vuetify)
Vue.use(VueQrcodeReader);
const opts = {}

export default new Vuetify(opts)




Vue.component('navapp', require('./components/navigation/Nav.vue').default);
Vue.component('snackbar', require('./components/mixins/Snackbar.vue').default);

import Home from './components/pages/Home.vue'
import Form from './components/pages/Form.vue'
const routes = [
  { path: '/', component: Home},
  {path: '/form', component: Form
  
}
]
const router = new VueRouter({
  mode: 'abstract',
routes
})
  new Vue({
    el: '#app',
    router,
     vuetify: new Vuetify({
      icons: {
       
        values: {
          checkboxOff: 'far fa-square',
          checkboxOn: 'fas fa-check-square',
          checkboxIndeterminate: 'fas fa-minus-circle',
          radioOff: 'far fa-circle',
          radioOn: 'fas fa-check-circle',
          clear: 'fa fa-ban',
          first: 'fas fa-arrow-circle-left',
          last: 'fas fa-arrow-circle-right',
          prev: 'fas fa-arrow-left',
          next: 'fas fa-arrow-right',
          dropdown: 'fas fa-caret-down'
        
        },
      },
      theme: {
        themes: {
          light: {
            primary: '#c5b187',
            secondary: '#193051',
            accent: '#8c9eff',
            error: '#b71c1c',
          },
        },
      },
     }),
    data: {
     csfr: window.axios.defaults.headers.common['X-CSRF-TOKEN'],
  
    },
    created: function () {
   
    },
    computed: {

    },
    methods:{

    }
  })
  router.replace('/')