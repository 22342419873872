<template>
  <div>
    <v-app-bar app color class="white">
<router-link to="/inventory">
        <v-img src="/img/logo.png" max-width="240px" link to="/inventory"></v-img>
</router-link>
   
      <v-list class="d-flex pl-5 pb-0 pr-2">
          <v-list-item-group  color="primary" class="d-flex flex-row">
        <v-list-item link to="/inventory">
          <v-list-item-action>
            <v-icon>fas fa-pen-square</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Inventar</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item link to="/bookings">
          <v-list-item-action>
            <v-icon>fas fa-archive </v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Buchungen</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-menu open-on-hover  offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item   v-bind="attrs"
          v-on="on" v-if="$root.user.role === 'admin'">
              <v-list-item-action>
                <v-icon>fas fa-tools</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Administration
                  <v-icon class="pl-2 pb-0" small>fas fa-caret-down</v-icon>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
              <v-list-item-group  color="primary">
            <v-list-item link to="/admin/manageuser" ><v-icon class="pr-2">fas fa-users</v-icon> Benutzerverwaltung</v-list-item>
              <v-list-item link to="/admin/massimport"><v-icon class="pr-2">fas fa-upload</v-icon>Import</v-list-item>
                   <v-list-item link to="/admin/export"><v-icon class="pr-2">fas fa-download</v-icon>Export</v-list-item>
          
              </v-list-item-group>  </v-list>

 
        </v-menu>
          </v-list-item-group>
          
      </v-list>
      <v-spacer></v-spacer>

      <v-menu offset-y>
        <template v-slot:activator="{ on }">
          <v-btn v-on="on" text class="ml-2">
            <v-icon class="mr-2" color="primary">fas fa-user</v-icon>
            {{$root.user.name}} &nbsp;
            <v-icon>fas fa-caret-down</v-icon>
          </v-btn>
        </template>
        <v-list>

          <v-list-item href="/logout">
            <v-list-item-title>
              <v-icon>fa fa-door-open</v-icon>&nbsp;Logout
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>
<script>
export default {
  props: {
    source: String,
  },

  data: () => ({
    drawer: null,
  }),
      created: function(){
console.log(this.$root.user.role)
  
   

  },
};
</script>