<template>
  <v-container class="fill-height" fluid>
    <v-row class="pb-6 pt-0 text-center" justify="center" align="center">
      <v-col cols="12" md="12" class="pt-0 pb-4">
        <p class="error">{{ error }}</p>

        <v-img
          src="img/logo.png"
          max-height="80px"
          contain
          transition="false"
        ></v-img>

        <v-btn class="mt-5" x-large icon @click="overlay = true"
          ><v-icon color="#c5b187" x-large>fas fa-qrcode</v-icon></v-btn
        >

        <p class="">QR-Code Scannen</p>

        <v-overlay :value="overlay">
          <div class="text-center">
            <v-progress-circular
              indeterminate
              v-if="loading"
              color="primary"
              size="64"
            ></v-progress-circular>
            <qrcode-stream :camera="camera" @init="onInit" @decode="onDecode">
              <v-btn
                @click="switchCamera"
                v-if="!loading"
                rounded
                class="primary"
                ><v-icon class="pr-2">fas fa-sync</v-icon>
                <div v-if="camera == 'rear'">Front-Kamera</div>
                <div v-else>Rück-Kamera</div>
              </v-btn>
              <v-btn color="success" @click="yalla()" class="mt-2"
                >Formular</v-btn
              >
            </qrcode-stream>
          </div>
        </v-overlay>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      result: "",
      camera: "rear",
      loading: true,
      error: "",
      overlay: false,
    };
  },

  methods: {
    onDecode(result) {
      axios.get("/api/scanCode?code=" + result).then(
        (response) => {
          console.log(response.data);
          if (!response.data)
            this.$root.$refs.snackbar.showmessage(
              "Objekt nicht gefunden.",
              "error"
            );
          else
            this.$router.push({ path: "form", query: { item: response.data } });
        },
        (error) => {
          this.$root.$refs.snackbar.showmessage(
            "Fehler bei Buildingabfrage: " + error,
            "error"
          );
        }
      );
    },
    yalla() {
      var result2 = "321313";
      axios.get("/api/scanCode?code=" + result2).then(
        (response) => {
          console.log(response.data);
          if (!response.data)
            this.$root.$refs.snackbar.showmessage(
              "Objekt nicht gefunden.",
              "error"
            );
          else
            this.$router.push({ path: "form", query: { item: response.data } });
        },
        (error) => {
          this.$root.$refs.snackbar.showmessage(
            "Fehler bei Abfrage: " + error,
            "error"
          );
        }
      );
    },
    switchCamera() {
      this.loading = true;
      switch (this.camera) {
        case "front":
          this.camera = "rear";
          break;
        case "rear":
          this.camera = "front";
          break;
      }
    },

    async onInit(promise) {
      try {
        await promise;
      } catch (error) {
        const triedFrontCamera = this.camera === "front";
        const triedRearCamera = this.camera === "rear";

        const cameraMissingError = error.name === "OverconstrainedError";

        if (triedRearCamera && cameraMissingError) {
          this.noRearCamera = true;
        }

        if (triedFrontCamera && cameraMissingError) {
          this.noFrontCamera = true;
        }

        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped>
.error {
  font-weight: bold;
  color: red;
}
</style>